<template>
  <div class="p-16">
    <h2>CILO Privacy Policy</h2>
    <br />
    <P>
      CILO regards in high importance the ​​basic privacy rights, and believes
      that these basic rights should not differ depending on where you live in
      the world. That’s why we treat any data that relates to an identified or
      identifiable individual or that is linked or linkable to them by CILO as
      “personal data,” no matter where the individual lives. This means that
      data that directly identifies you — such as your name — is personal data,
      and also data that does not directly identify you, but that can reasonably
      be used to identify you — such as the serial number of your device — is
      personal data. Aggregated data is considered non-personal data for the
      purposes of this Privacy Policy. This Privacy Policy (**Policy**)
      describes how we at CILO Global Corp. collect, use, share, and secure
      your Personal Information when you visit the CILO Platform
      (https://cilo.io) (the **Platform**) or create, buy, transfer, or trade
      unique CILO Items in our Market (the **Market**).
    </P>
    <br />
    <p>
      By accessing the Site or trading CILO Items through the Market, you
      agree to this Privacy Policy and our Terms of Use.
    </p>
    <br />
    <br />
    <h2>Personal Information CILO Collects</h2>
    <br />
    <p>
      We may collect a variety of information to identify you as an individual
      ("Personal Information"). The personal data CILO collects depends on how
      you interact with CILO. Personal Information does not include anonymous
      or aggregate information that does not identify you as an individual. The
      Personal Information CILO collects to be a user, an artist, and a
      collector as follows:
    </p>
    <br />
    <ul>
      <li>
        <h3>Creating a User or a Collector Account</h3>
        <p>
          If you sign up to use our Platform, we collect your Ethereum wallet
          address, username, and email address. The User may elect to provide
          their full name, location, biographical information, and a website.
        </p>
      </li>
      <li>
        <h3>Applying as an Artist</h3>
        <p>
          If you want to become digital artists in our Market, we collect your
          first and last name, email address, a short application video,
          Ethereum wallet address, and information about your art.
        </p>
      </li>
      <li>
        <h3>Visiting the Site or Market</h3>
        <p>
          We also collect basic analytics data when you access or use the
          Platform through Cookies. See our Cookies Policy below for more
          information about the Cookies we use and what data they may collect.
        </p>
      </li>
    </ul>
    <br />
    <br />
    <h2>Use of Personal Information</h2>
    <br />
    <p>We use the Personal Information we collect to:</p>
    <ul>
      <li>
        <p>
          Make the Platform work and enable you to list, buy, transfer, or trade
          unique digital art;
        </p>
      </li>
      <li>
        <p>
          Provide you with critical updates, confirmations, or security alerts;
        </p>
      </li>
      <li>
        <p>Provide support or respond to your comments or questions;</p>
      </li>
      <li>
        <p>Personalize and improve your experience;</p>
      </li>
      <li>
        <p>
          Inform you about products, services, news, or promotional
          opportunities we think might interest you;
        </p>
      </li>
      <li>
        <p>Analyze and improve our Platform.</p>
      </li>
    </ul>
    <br />
    <br />
    <h2>Sharing Personal Information</h2>
    <br />
    <p>
      CILO may share personal information with CILO-affiliated companies,
      service providers who act on our behalf, our partners, developers, and
      publishers, or others at your direction. We work hard to ensure these
      providers only access and use your information as necessary to perform
      their functions. Further, CILO does not share personal information with
      third parties for their own marketing purposes.
    </p>
    <br />
    <br />
    <h2>Cookies & Other Technologies</h2>
    <br />
    <p>
      CILO’s websites, online services, interactive applications, and
      advertisements may use <strong>“Cookies”</strong> and other technologies
      such as web beacons. These technologies help us to better understand user
      behavior including for security and fraud prevention purposes, tell us
      which parts of our websites people have visited, and facilitate and
      measure the effectiveness of advertisements and web searches.
    </p>
    <br />
    <br />
    <h2>Type of Cookies</h2>
    <br />
    <h3>Communications Cookies</h3>
    <p>
      These cookies are used to enable network traffic to and from CILO’s
      systems, including by helping us detect any errors.
    </p>
    <br />
    <h3>Strictly Necessary Cookies</h3>
    <p>
      These cookies are set as required to provide a specific feature or service
      that you have accessed or requested. For example, they allow us to display
      our websites in the proper format and language, to authenticate and verify
      your transactions, and to preserve the contents when buying, trading, and
      transferring at CILO.io.
    </p>
    <br />
    <h3>Other Cookies</h3>
    <p>
      These cookies are used to understand how visitors interact with our
      websites and online services, including by helping us to assess the
      effectiveness of advertisements and web searches. CILO also uses these
      cookies to remember choices you make while browsing, so we can provide you
      with a customized experience.
    </p>
    <br />
    <h2>How you can control Cookies</h2>
    <br />
    <p>
      If you wish to prevent Cookies from tracking your activity on our Platform
      or visits across multiple websites, you can set your browser to block
      certain Cookies or notify you when a Cookie is set. For more information
      on how you can customize your browser’s Cookie setting please visit the
      link to your web browser below:
    </p>
    <ul>
      <li>Chrome</li>
      <li>Safari</li>
      <li>Internet Explorer</li>
      <li>Mozilla</li>
    </ul>
    <br />
    <p>
      You may opt-out of interest-based advertising in general by visiting the
      Digital Advertising Alliance’s or Network Advertising Initiative’s
      websites. We are not responsible for the completeness, effectiveness, or
      accuracy of any third-party opt-out options or programs. If you block
      Cookies, some features that make the Platform function property may not
      work and your experience may be affected.
    </p>
    <br />
    <br />
    <h2>Privacy Rights at CILO</h2>
    <br />
    <p>
      At CILO, we respect your ability to know, access, correct, transfer,
      restrict the processing of, and delete your Personal Information. We have
      provided these rights to our global customer base and if you choose to
      exercise these privacy rights, you have the right not to be treated in a
      discriminatory way nor to receive a lesser degree of service from CILO.
      You may contact us at cs@CILO.io to erase, update or correct any
      Personal Information we collected about you and to unsubscribe from
      commercial or marketing communications from us.
    </p>
    <br />
    <br />
    <h2>Children and Personal Information</h2>
    <br />
    <p>
      The Platform is not intended for use by children under the age of 18, and
      we do not knowingly collect Personal Information from children under the
      age of 13. If we learn we collected Personal Information from a child
      under 13 we will delete that information from our files.
    </p>
    <br />
    <br />
    <h2>Third-Party Links and Services</h2>
    <br />
    <p>
      Our Policy only applies to our Platform and does not apply to third-party
      websites or services because we do not control those third parties. We are
      not responsible for the privacy practices or content of third-party
      websites. We encourage you to review the privacy policies of those
      third-party sites to learn about their practices or what rights you may
      have.
    </p>
    <br />
    <br />
    <h2>The Digital Millennium Copyright Act</h2>
    <br />
    <p>
      If the person claiming the copyright explains to CILO that his/her work
      has been uploaded without permission, CILO considers the explanation to
      be true and must immediately delete the work. As long as CILO faithfully
      follows the DMCA procedures, CILO will be free of copyright
      infringement.
    </p>
    <br />
    <br />
    <h2>Important Limitations</h2>
    <br />
    <p>Nothing in this Policy restricts our ability to:</p>
    <ul>
      <li>
        Comply with applicable law, rules, or regulations, including the DMCA;
      </li>
      <li>
        Comply with a civil, criminal, or regulatory inquiry, investigation,
        subpoena, or lawful court order;
      </li>
      <li>
        Cooperate with law enforcement agencies about conduct we reasonably and
        in good faith believe may violate the law;
      </li>
      <li>Protect our rights or our property;</li>
      <li>Enforce our Terms of Service; or,</li>
      <li>
        Prevent behavior that is (or that we think may be) illegal or unethical.
      </li>
    </ul>
    <br />
    <br />
    <h2>Changes to this Policy</h2>
    <br />
    <p>
      Our commitment to preserving your privacy will not change, but our
      Platform may evolve. The effective date of each version is identified
      above. If we make material changes to this Privacy Policy, we will use
      reasonable means to inform you and, where necessary, obtain your consent.
    </p>
    <br />
    <br />
    <h2>Questions</h2>
    <br />
    <p>
      If you have questions about this Privacy Policy, please contact us at
      cs@CILO.io.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2 {
  font-size: 1.4rem;
  font-weight: 600;
}
h3 {
  font-weight: 600;
}
p {
  padding-left: 1rem;
  text-align: justify;
}
li {
  list-style: circle;
  margin-left: 3rem;
  text-align: justify;
}
</style>